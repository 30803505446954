import React, { useState } from "react"
import { AnimatePresence } from "framer-motion"

import Diamond from "../components/diamond"

import "./main.scss"

const MainLayout = ({ children }) => {
  const [darkMode, setDarkMode] = useState(false)

  function toggleDarkMode() {
    setDarkMode(!darkMode)
  }

  return (
    <>
      <div className={darkMode ? "dark" : "light"}>
        <button
          className="diamond-wrapper"
          width="30px"
          height="30px"
          onClick={toggleDarkMode}
          tabIndex={-1}
        >
          <Diamond />
        </button>
        <AnimatePresence exitBeforeEnter>{children} </AnimatePresence>
      </div>
    </>
  )
}

export default MainLayout
