module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[],"component":"/opt/build/repo/src/layouts/mainLayout.js"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Bilgesu Korkmaz","short_name":"Su Korkmaz","start_url":"/","background_color":"#ffffff","theme_color":"#fa4606","display":"standalone","icon":"src/images/maskable_icon.png","icons":[{"src":"/favicons/maskable_icon_512x512.png","sizes":"512x512","type":"image/png","purpose":"any maskable"},{"src":"/favicons/maskable_icon_192x192.png","sizes":"192x192","type":"image/png","purpose":"any maskable"}],"crossOrigin":"use-credentials","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"d045232c72f42adc247c945de11391a6"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
