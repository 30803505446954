import React from "react"

const Diamond = ({ width, height }) => {
  return (
    <>
      <svg
        version="1.1"
        baseProfile="tiny"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        width={width}
        height={height}
        viewBox="0 0 450 391"
        overflow="visible"
      >
        <g>
          <g>
            <path
              d="M448.4,128.3l-60-120c-2.9-5.1-7-8.3-13.4-8.3H75c-6.3,0-10.5,3.1-13.4,8.3l-60,120c-2.8,5.9-1.8,11.8,2.1,16.6l210,241
			c2.9,3.3,7,5.1,11.3,5.1s8.5-1.9,11.3-5.1l210-241C450.2,140.1,451.2,134.2,448.4,128.3z M350.7,30l-38.3,76.6L255,30H350.7z
			 M285,120H165l60-80L285,120z M195,30l-57.4,76.6L99.3,30H195z M75,48.5l35.7,71.5H39.3L75,48.5z M48,150h77.1l74,173.4L48,150z
			 M225,307.8L157.7,150h134.6L225,307.8z M251,323.4L324.9,150H402L251,323.4z M339.3,120L375,48.5l35.7,71.5H339.3z"
            />
          </g>
        </g>
      </svg>
    </>
  )
}

export default Diamond
